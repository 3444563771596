<template>
  <div class="howby-bg"></div>
</template>

<script>
export default {
  name: "HowBuy",
};
</script>

<style>
.howby-bg {
  background-image: url("../../public/images/howbuy(3).png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
