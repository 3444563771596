<template>
  <section class="pt-0 pb-0 token-section">
    <div class="token-container">
      <div class="flex justify-center">
        <h1 class="font-section-title pb-20">Tokenomics</h1>
      </div>
      <div class="sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid gap-6">
        <div class="grid-item">
          <img class="grid-img" src="images/mint.png" />
          <h3 class="grid-text">Mint & Freeze Revoked</h3>
        </div>
        <div class="grid-item">
          <img class="grid-img flex justify-center" src="images/taxes.png" />
          <h3 class="grid-text">Zero Taxes</h3>
        </div>
        <div class="grid-item">
          <img class="grid-img" src="images/liquidity.png" />
          <h3 class="grid-text">Liquidity Burned</h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TokenomicsPage",
};
</script>

<style>
.token-section {
  background-color: #d0eac3;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fbbe9b;
  border: 2px solid black;
  border-radius: 8px;
  padding: 20px;
}

.grid-img {
  width: 200;
  height: auto;
}

.token-container {
  padding: 5% 20%;
}

.grid-text {
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
