<template>
  <div class="bg-white flex flex-col about-bg">
    <div class="flex-grow grid md:grid-cols-2 gap-4 p-4 items-center">
      <div class="iframe-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/5W65LdcaWvI"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div
        class="bg-white text-black text-lg p-4 rounded-xl border-4 border-black"
      >
        <h1 class="font-section-title pb-4">About Us</h1>
        <p>Lucky red panda on Solana.</p>
        <br />
        <p>
          The red panda symbolizes harmony in nature, blending adaptability and
          resilience with playful joy. Revered as a guardian of the forest, it
          highlights the importance of conservation and celebrates the
          interconnectedness of all life.
        </p>
        <br />
        <p>
          We will be donating to redpandanetwork and virtually adopt a red panda
          family. Donating to red pandas helps conserve these endangered animals
          and their habitats, preserving biodiversity and raising awareness
          about wildlife conservation. Join the community and help us save the
          red pandas!
        </p>
        <br />
        <p>https://redpandanetwork.org/donate</p>
      </div>
    </div>
    <!-- Socials Section, sticks to the bottom -->
    <div class="bg-green-700 p-4">
      <div class="flex justify-center space-x-4">
        <a
          href="your-facebook-link"
          target="_blank"
          class="text-white hover:text-gray-200"
          >Pump</a
        >
        <a
          href="your-twitter-link"
          target="_blank"
          class="text-white hover:text-gray-200"
          >Twitter</a
        >
        <a
          href="your-instagram-link"
          target="_blank"
          class="text-white hover:text-gray-200"
          >Telegram</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUsSocials",
};
</script>

<style>
.about-bg {
  background-image: url("../../public/images/about-us-background.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
/* Add styles for the iframe container to make it responsive */
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.iframe-container iframe {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
}
</style>
