<template>
  <div class="flex flex-col h-screen hero-background overflow-x-hidden">
    <div class="flex-grow flex justify-center items-center">
      <div class="w-full flex flex-col items-center">
        <h1 class="title">Red Panda</h1>
        <button
          @click="toggleMusic"
          :class="{ 'music-playing': isPlaying, 'music-stopped': !isPlaying }"
          class="music-btn"
        ></button>
      </div>
    </div>
    <div class="leaves-container">
      <div
        v-for="leaf in leaves"
        :key="leaf.id"
        :style="leaf.style"
        class="leaf"
      ></div>
    </div>
    <vue-marquee-slider
      class="w-screen bg-black bg-opacity-50 p-10 rounded-lg font-ticker"
      :speed="1500"
      :width="50"
    >
      <span>$PANDA</span>
    </vue-marquee-slider>
  </div>
</template>

<script>
import { VueMarqueeSlider } from "vue3-marquee-slider";
import "../../node_modules/vue3-marquee-slider/dist/style.css";

import musicFile from "../../public/music/Echoes_of_Dawn.mp3";
export default {
  name: "HeroPage",
  components: {
    VueMarqueeSlider,
  },
  data() {
    return {
      leaves: [],
      isPlaying: false,
      music: new Audio(musicFile),
    };
  },
  methods: {
    addLeaf() {
      if (this.leaves.length >= 20) {
        return;
      }

      const id = Math.random().toString(36).substr(2, 9);
      const animationDuration = Math.random() * (10 - 5) + 5; // Between 5 and 10 seconds
      const leftPosition = Math.random() * 100; // Percentage of screen width

      const leaf = {
        id: id,
        style: {
          animation: `fall ${animationDuration}s linear infinite`,
          left: `${leftPosition}%`,
        },
      };

      this.leaves.push(leaf);
    },

    toggleMusic() {
      if (this.isPlaying) {
        this.music.pause();
      } else {
        this.music.play();
      }
      this.isPlaying = !this.isPlaying;
    },
  },
  mounted() {
    setInterval(this.addLeaf, 500);
  },
};
</script>

<style>
.hero-background {
  background-image: url("../../public/images/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.font-ticker {
  @apply font-bamboo text-green-500;
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}

.title {
  @apply font-bamboo text-9xl text-white;
  text-shadow: 2px 2px #000;
}

@keyframes fall {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
  0% {
    transform: translateY(-30%) translateX(0);
  }
  50% {
    transform: translateY(50vh) rotate(-45deg);
  }
  100% {
    transform: translateY(100vh) translateX(0%);
  }
}

.leaves-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.leaf {
  position: absolute;
  width: 64px;
  height: 64px;
  background-image: url("../../public/images/leaf.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.music-btn {
  background-image: url("../../public/images/play-music-btn.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 400px; /* Adjust as necessary */
  height: 50px; /* Adjust as necessary */
  border: none;
  cursor: pointer;
  background-size: contain;
  transition: transform 0.2s; /* Smooth transition for hover effect */
  margin-left: 4%;
}

.music-btn:hover {
  transform: scale(1.1); /* Slightly enlarge button on hover */
}

.music-playing {
  background-image: url("../../public/images/stop-music-btn.png"); /* Change to your stop button image */
  animation: jiggle 0.5s infinite;
}

.music-stopped {
  background-image: url("../../public/images/play-music-btn.png"); /* Your original play button image */
}

@keyframes jiggle {
  0%,
  100% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
}
</style>
