<template>
  <div class="flex flex-col">
    <HeroPage class="rp-section" />
    <AboutUsSocials />
    <HowToBuy class="rp-section" />
    <TokenomicsSection />
    <PartnersSection />
  </div>
</template>

<script setup></script>

<script>
import AboutUsSocials from "./components/AboutUsSocials.vue";
import HeroPage from "./components/HeroPage.vue";
import HowToBuy from "./components/HowBuy.vue";
import PartnersSection from "./components/PartnersSection.vue";
import TokenomicsSection from "./components/TokenomicsSection.vue";

export default {
  name: "App",
  components: {
    HeroPage,
    AboutUsSocials,
    HowToBuy,
    TokenomicsSection,
    PartnersSection,
  },
  data() {
    return {
      stars: this.createStars(30),
    };
  },
  methods: {
    createStars(numStars) {
      const starImages = ["s1.png", "s2.png"]; // Assuming these are the paths to your images
      return Array.from({ length: numStars }, (_, id) => ({
        id,
        image: `img/${
          starImages[Math.floor(Math.random() * starImages.length)]
        }`,
        class: `rotate-${Math.ceil(Math.random() * 3)}`, // Assigning a rotate class randomly
        style: {
          top: `${Math.random() * 100}%`,
          left: `${Math.random() * 100}%`,
          animation: `spin ${10 + Math.random() * 20}s linear infinite`,
        },
      }));
    },
  },
};
</script>

<style>
.rp-section {
  @apply min-h-screen flex text-white;
}

.font-section-title {
  @apply font-bamboo text-green-500;
  font-size: 5rem; /* 36px */
  line-height: 5rem; /* 40px */
}
</style>
