<template>
  <div class="token-section">
    <div class="flex justify-center">
      <h1 class="font-section-title py-20">Our Partners</h1>
    </div>
    <Vue3Marquee :clone="true" :duration="3" class="pb-20">
      <img v-for="img in imgArray" :key="img" :src="img" class="partner-img" />
    </Vue3Marquee>
  </div>
</template>

<script>
import { Vue3Marquee } from "vue3-marquee";
export default {
  name: "PartnersSection",
  components: {
    Vue3Marquee,
  },
  data() {
    return {
      imgArray: [
        "images/solana.png",
        "images/phantom.webp",
        "images/raydium.webp",
        "images/coingecko.webp",
      ],
    };
  },
};
</script>

<style>
.partner-img {
  @apply mx-10;
  width: 50px;
  height: 50px;
}
</style>
